.toggle-list {
  font-size: 24px;
  font-weight: 300;
  font-family: "Epilogue", sans-serif;

}  

ul {
  list-style-type: none;
  padding: 0;
}

.toggle-list li {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0%;
  grid-gap: 5%;
  justify-content: center;
  align-items: center;
  font-size: 2vw;
  padding: 2%;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  transition: padding 0.3s ease;
  pointer-events: auto; /* Ensure that pointer events are enabled */

  cursor: pointer;
  border-bottom: 1px solid #ccc;
  transition: all 0.3s ease; 
}

.toggle-list li.active {
  padding: 3%;
}


.additional-content {
  display: grid;
  gap: 5%;
  grid-template-columns: 200px 1fr; /* Adjust column widths as needed */
  padding: 10px;
  font-size: 2vmin;
  transition: max-height 0.3s ease;
}

.image-container {
  width: 100%; /* Adjust width to fill the column */
  height: 200px;
  overflow: hidden;
  position: relative;
}

.image-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.detail-info {
  grid-column-start: 2;
}

.title {
  text-align: start;
  direction: ltr;
  max-width: 100%;
  display: inline-block;
  fill: transparent; /* Set default text to stroke */
  stroke: white; /* Set default stroke color */
  stroke-width: 1px; /* Set default stroke width */
  transition: fill 0.3s ease; /* Add transition for smooth animation */

  font-weight: 700;
  font-size: 2.5vw;
}

.title:hover {
  fill: white; /* Change text to fill on hover */
  stroke: none; /* Remove stroke on hover */
}

.time {
  text-align: end;
  direction: ltr;
  
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .title {
    width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .title {
    width: 80%;
  }
}
