@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&family=Sora:wght@100..800&display=swap');


body {
  font-family: "Epilogue", sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
}

header {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.main-content {
  flex: 1;
}

.left-sidebar, .right-sidebar {
  flex: 1 0 200px;
  background-color: #f0f0f0;
  padding: 10px;
}

.content {
  flex: 2 0 400px;
  background-color: #e0e0e0;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 768px) {
  .content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}
