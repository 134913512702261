body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #fff;
  font-size: 20px;
  font-family: "Epilogue", sans-serif;
  margin: 0;
}

.background {
  background-image: url('../pic/1.jpg'); /* or background-color: #XXXXXX; */
  background-size: cover; /* Ensure the background covers the entire area */
  background-position: center; /* Center the background image */
  height: 100vh; /* Set the height to the viewport height */
  position: relative;
  pointer-events: none;
}
.content-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content{
  background: linear-gradient(to top, rgba(0, 15, 98, 1), rgba(4, 50, 141, 0.8), rgba(8, 84, 185, 0));
  /* For older browsers */
  background: -webkit-linear-gradient(bottom, rgba(0, 15, 98, 1), rgba(4, 50, 141, 0.8), rgba(8, 84, 185, 0));
  /* Ensure the gradient covers the entire viewport */
  height: 100vh;
  padding: 7%;
  }

  
.headline {
  font-size: 5vw; /* Responsive heading size */
  font-weight: 700;

  padding-top: -5%;
  padding-bottom: -2%;
}

.intro {
  font-size: 2vw;
  font-weight: 400;
}

  
