body{
    font-family: "Epilogue", sans-serif;
    font-weight: 600;
    font-size: 24;
}

.grid-container {
    display: flex;
    justify-content: space-around;
  }
  
.grid-item {
    text-align: left;
    flex: 1;
    padding: 5% 3%;
    max-width: 18%; /* Optionally, set a max-width for each grid item */
    font-size: 1vw;
}

h3 {
    font-size: 1.5vw;
}

p{
  font-weight: 300;
}

h4{
  max-width: 50%;
}
.link {
    color: #fff;
    position: relative;
    text-decoration: none;
    font-size: 3vw;
  }
  
.link::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background-color: #fff;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
  }
  
.link:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }


/* Footer.css */

/* Add styles for social media icons */
.social-icons {
  display: flex;
  justify-content: left;
  margin-top: 2%; /* Adjust spacing between content and icons */
  padding: 6%;
}

.social-icons a {
  color: #fff;
  margin: 0 2%; /* Adjust spacing between icons */
}


  
  