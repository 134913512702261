/* Navbar.css */
body{
  font-family: "Epilogue", sans-serif;
  font-weight: 600;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, rgba(0, 15, 98, 1), rgba(4, 50, 141, 0));
  padding: 0; /* Remove padding */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.menu-button {
  background-color: transparent;
  color: white;
  border: none;
  transition: transform 0.6s ease; /* Transition for icon transform */
}

.menu-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #000;
  opacity: 90%;
  align-items: center;
  font-size: 80px;
}

.menu-screen ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.menu-screen ul li {
  font-weight: 'Grand-Bold';
  margin: 50px;
  font-size: 4.5vw;
  -webkit-text-stroke: 1px white; /* White stroke */
  color: transparent; /* Hide the text color */
  transition: color 0.3s ease; /* Transition for text color change */
}

.menu-screen a {
  text-align: start;
  direction: ltr;
  max-width: 100%;
  display: inline-block;
  color: transparent; /* Set text color to transparent */
  -webkit-text-stroke: 1px white; /* Add stroke to the text */
  transition: color 0.3s ease; /* Transition for smooth color change */
  text-decoration: none;
}

.menu-screen a:hover{
  color: white;
}

.navbar-left {
  padding: 2%;
  display: flex; 
  align-items: center;
}

.navbar-right {
  padding: 2%;
  display: flex;
  align-items: center;
  background-size: cover;
  height: 10%;
  width: auto;
}

/* Navbar.css */

/* Add styles for social media icons */
.social-icons {
  display: flex;
  align-items: center;
  padding: 10%;
}

.social-icons a {
  color: white;
  margin-right: 10px; /* Adjust spacing between icons */
}
